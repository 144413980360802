import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import NavBar from '../../common/NavBar';
import ContentCard from '../../common/ContentCard';
import ImportUsersForm from './ImportUsersForm';
import Footer from '../../common/Footer';
import { initializeDefaultTheme } from "../../../utils/clientTheme";

export default class ImportUsers extends Component {
  constructor(props) {
    super(props);
    initializeDefaultTheme();
    this.state = {
      PENDING: false,
    }
  }

  render() {
    return (
      <Fragment>
      <div className="wrapper bg-dashboard">
        <NavBar />
        <Container>
          <Col xs="8">
          <Row className="py-4">
            <Col sm="8">
              <h2>
                Import Users
              </h2>
            </Col>
            <Col sm="4" className="text-right">
            <Button color="c-primary" onClick={() => this.props.history.goBack()}>
                <i className="fas fa-arrow-left"></i> <span>Back</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <ContentCard>
                <ImportUsersForm history={this.props.history} />
              </ContentCard>
            </Col>
          </Row>
          </Col>
        </Container>
      </div>
      <Footer />
      </Fragment>
    )
  }
}