import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';

export const availableAppsNames = [
  {
    originalName: 'FuelLine',
    displayName: 'Adaptive Fuel Line',
  },
  {
    originalName: 'PureBallastServices',
    displayName: 'PureBallast Services',
  },
  {
    originalName: 'SPCompetitorInsight',
    displayName: 'S&P Competitor Insight',
  },
  {
    originalName: 'MIB',
    displayName: 'MIB',
  },
  {
    originalName: 'PureBallast',
    displayName: 'PureBallast',
  },
  {
    originalName: 'SolarPower',
    displayName: 'SolarPower',
  },
  {
    originalName: 'CarePlus',
    displayName: 'Separator Uptime Protection Package',
  },
  {
    originalName: 'MarineDigitalServicesTool',
    displayName: 'Marine Digital Services Tool',
  },
  {
    originalName: 'PureNOxService',
    displayName: 'PureNOx Service',
  },
  {
    originalName: 'MarineOverview',
    displayName: 'Marine Overview',
  },
  {
    originalName: 'SeparatorVsFilterOperation',
    displayName: 'Separator vs Filter Operation',
  },
  {
    originalName: 'MuntersSprayDrying',
    displayName: 'Munters Spray Drying',
  },
  {
    originalName: 'MuntersHeatPump',
    displayName: 'Munters HeatPump',
  },
  {
    originalName: 'MuntersGreenDry',
    displayName: 'Munters GreenDry',
  },
  {
    originalName: 'PureBallastDrydock',
    displayName: 'PureBallast Drydock',
  }
];

export const decodeToken = () => {
  let token = '';
  if (!!Cookie.get('access_token')) {
    token = Cookie.get('access_token');
  } else {
    token = sessionStorage.getItem('access_token');
  }

  return jwtDecode(token);
};

export const changeAppName = (appName) => {
  const app = availableAppsNames.find((name) => name.originalName === appName);
  return app ? app.displayName : appName;
};

export const translateRoleName = (role) => {
  if (role === 'user') return 'User';
  if (role === 'appAdmin') return 'Application Administrator';
  if (role === 'admin') return 'System Administrator';
  if (role === 'superUser') return 'Super User';
  if (role === 'businessManager') return 'Business Manager';
};

export const renderApplicationsWithDisplayName = (applicationsArray) => {
  return applicationsArray.map((app) => ({
    originalName: app,
    displayName: changeAppName(app),
  }));
};

export const sortApplicationsByDisplayName = (applicationsArray) => {
  // const applicationsWithDisplayName = applicationsArray.map(app => ({
  //   originalName: app,
  //   displayName: changeAppName(app)
  // }))

  applicationsArray.sort((a, b) => {
    const nameA = a.displayName.toLowerCase();
    const nameB = b.displayName.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return applicationsArray;
};
