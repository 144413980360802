import React, { useRef } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import LoadingButton from '../../../common/LoadingButton';
import NotificationAlert from 'react-notification-alert';
import { changeAppName, sortApplicationsByDisplayName } from '../../../../utils/helpers';

const AppsModal = ({
  toggle,
  isOpen,
  isPending,
  isFileUploaded,
  onSubmitFn,
  applications,
  handleCheckboxChangeFn,
  isChecked,
  clients,
  selectedClient,
  handleClientChange,
  hasAdminRole,
}) => {
  console.log(selectedClient);
  const visibleApps =
    selectedClient === 'SystemAdministration' ? applications : clients.find((client) => client.client === selectedClient).applications;

  const refNotification = useRef();

  const renderCheckboxes = (sortedApplications) => {
    return sortedApplications.map((app) => {
      return (
        <FormGroup key={app.originalName} check>
          <Label check>
            <Input type='checkbox' onChange={(e) => handleCheckboxChangeFn(e, app.originalName)} /> {app.displayName}
          </Label>
        </FormGroup>
      );
    });
  };

  const appsWithDisplayName = visibleApps.map((a) => {
    return clients.length === 0
      ? {
          originalName: a.application,
          displayName: changeAppName(a.application),
          role: a.role,
          active: a.active,
        }
      : {
          originalName: a,
          displayName: changeAppName(a),
          role: a.role,
          active: a.active,
        };
  });

  const sortedApps = sortApplicationsByDisplayName(appsWithDisplayName);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select applications</ModalHeader>
      {applications.length > 0 ? (
        <>
          <ModalBody>
            Select applications that you want imported users to be added to
            <Form className='pt-3'>
              {renderCheckboxes(sortedApps)}
              {hasAdminRole && (
                <FormGroup className='mb-0 mt-3'>
                  <Label for='client'>Client</Label>
                  <Input
                    id='company'
                    type='select'
                    placeholder='Select client...'
                    name='client'
                    value={selectedClient}
                    onChange={handleClientChange}
                  >
                    {clients.map((client) => (
                      <option key={client.client} value={client.client}>
                        {client.client}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
              <NotificationAlert ref={refNotification} />
            </Form>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              loading={isPending}
              disabled={isPending || !isFileUploaded || !isChecked}
              color='c-primary'
              type='submit'
              onClick={onSubmitFn}
            >
              Submit
            </LoadingButton>
            <Button color='link' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      ) : (
        <ModalBody>No applications available</ModalBody>
      )}
    </Modal>
  );
};

export default AppsModal;
