import React from 'react'
import { Button } from 'reactstrap';

export default function LoadingButton(props) {
  const { loading, ...rest } = props;
  return (
    <Button {...rest}>
        {loading && <i className="fas fa-spinner fa-spin mr-2"></i>}
        {props.children}
    </Button>
  )
}

