import request from './request';

export const getMe = () => request({
  url: `/api/users/me`,
  method: 'GET',
  headers: {},
});

export const getUsers = (offset, searchText, client = '', apps) => request({
  url: apps ? `/api/users?offset=${offset}&searchText=${searchText}&client=${client}&apps=${apps}` :
      `/api/users?offset=${offset}&searchText=${searchText}&client=${client}`,
  method: 'GET',
  headers: {},
});

export const createUser = body => request({
  url: '/api/users',
  method: 'POST',
  headers: {},
  data: body,
});

export const updateUser = (uuid, body) => request({
  url: `/api/users/${uuid}`,
  method: 'PATCH',
  headers: {},
  data: body,
});

export const removeUser = userId => request({
  url: `/api/users/${userId}`,
  method: 'DELETE',
  headers: {},
});

export const requestPasswordChange = body => request({
  url: '/api/users/requestPasswordChange',
  method: 'POST',
  headers: {},
  data: body,
});

export const setPassword = body => request({
  url: '/api/users/setPassword',
  method: 'POST',
  headers: {},
  data: body,
});

export const importFile = (format, file, apps, client) => request({
  url: `/api/users/import?format=${format}&apps=${apps}&client=${client}`,
  method: 'POST',
  headers: {
    // 'Content-Type': `mulitipart/form-data`
  },
  data: file,
});

export const getApplications = (all) => request({
  url: `/api/applications?all=${all}`,
  method: 'GET',
  headers: {},
});

export const assignToApplication = (uuid, body) => request({
  url: `/api/users/${uuid}/assignToApplication`,
  method: 'POST',
  headers: {},
  data: body,
});

export const unassignFromApplication = (uuid, body) => request({
  url: `/api/users/${uuid}/unassignFromApplication`,
  method: 'POST',
  headers: {},
  data: body,
});

export const getRoles = () => request({
  url: `/api/roles`,
  method: 'GET',
  headers: {},
});

export const getClients = () => request({
  url: `/api/clients`,
  method: 'GET',
  headers: {},
});

export const validateToken = (token) => request({
  url: `/api/users/validateConfirmationToken?confirmationToken=${token}`,
  method: 'GET',
  headers: {}
});

export const checkUsername = username => request({
  url: `/api/users/checkUsername`,
  method: 'POST',
  headers: {},
  data: { username }
});
