import React, { useState, createContext} from 'react';

export const CurrentUserContext = createContext({});

export const CurrentUserContextProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState({});
  const [clients, setClients] = useState([]);
  return (
    <CurrentUserContext.Provider value={{
      currentUser: currentUser,
      setCurrentUser: user => setCurrentUser(user),
      clients: clients, setClients: clientsData => setClients(clientsData),
    }}>
      {children}
    </CurrentUserContext.Provider>
  )
};
