import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

export const isAdmin = () => {
  let token = '';
  if(!!Cookie.get('access_token')) {
    token = Cookie.get('access_token');
  } else {
    token = sessionStorage.getItem('access_token');
  }
  return !!token && (jwtDecode(token.slice(7)).role === 'admin' || 'appAdmin')
};
