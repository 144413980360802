import React from 'react';

export default function IE11Warning(props) {
  // Get IE or Edge browser version
  let version = detectIE();
  let ie11 = false;

  if (version === false) {
    //Not Microsoft
  } else if (version >= 12) {
    //Edge
  } else {
    ie11 = true;
  }
  return (ie11 ?
    <div className="text-center small mt-3">
      <div>
        <b>This application uses new features and heavy calculations that will run slow in an old browser.</b>
      </div>
      <br />
      A newer browser is recommended for this application.
      <br />(Ex: <a target="_blank" href="https://www.google.com/chrome/" rel="noopener noreferrer">Chrome</a> or &nbsp;
      <a target="_blank" href="https://www.microsoft.com/sv-se/windows/microsoft-edge" rel="noopener noreferrer">Edge</a>)
    </div>
    : null);
}


/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
