import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { Formik } from 'formik';
import Cookie from 'js-cookie';
import NotificationAlert from 'react-notification-alert';

import { login } from '../../../api/login';
import LoadingButton from '../../common/LoadingButton';
import Footer from '../../common/Footer';
import IE11Warning from './IE11Warning'
import { initializeDefaultTheme, resetClientTheme } from "../../../utils/clientTheme";

class Login extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      PENDING: false
    }
    initializeDefaultTheme();
  }

  triggerNotification = (message) => {
    this.refs.notification.notificationAlert({place: 'tr', message, type:'danger', autoDismiss: 5});
  }

  onSubmitHandler = (values, actions) => {
    if(this.state.PENDING) return;
    this.setState({ PENDING: true });
    login({
      username: values.username,
      password: values.password,
    })
    .then(response => {
      resetClientTheme();
      if(values.remember) {
        Cookie.set('access_token', response.data.data.jwt, { expires: 365});
        Cookie.set('refresh_token', response.data.data.refreshToken, { expires: 365});
      } else {
          sessionStorage.setItem('access_token', response.data.data.jwt);
          sessionStorage.setItem('refresh_token', response.data.data.refreshToken);
        }
        actions.setSubmitting(false);
        this.props.history.push('/users');
      })
      .catch(err => {
        this.setState({PENDING: false});
        if(err.status === 401) {
        this.triggerNotification('Invalid credentials');
      } else if(err.status === 403) {
        this.triggerNotification("You don't have access to this application");
      } else if(err.status === 500) {
        this.triggerNotification('Internal server error');
      } else {
        this.triggerNotification(err.data.meta.errors[0].reason);
      }
      actions.setSubmitting(false);
    })
  };

  render() {
    return (
      <Fragment>
        <div className="wrapper bg-ship">
          <Container>
            <Row>
              <Col sm="9" md="7" lg="4" className="mx-auto mt-5">
                <Card className="card-signin my-5">
                  <CardBody>
                    <h2 className="card-title">Sign in</h2>
                    <Formik
                      initialValues={{ username: '', password: '', remember: false }}
                      onSubmit={(values, actions) => this.onSubmitHandler(values, actions)}
                      render={({ handleSubmit, handleChange, values, errors }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormGroup>
                            <Input
                              onChange={handleChange}
                              value={values.username}
                              type="email"
                              name="username"
                              id="usernameInput"
                              placeholder="E-mail..."
                              required
                              autoFocus
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              onChange={handleChange}
                              value={values.password}
                              name="password"
                              type="password"
                              id="passwordInput"
                              placeholder="Password..."
                              required
                            />
                          </FormGroup>
                          <FormGroup className="mb-3" check>
                            <Label check>
                              <Input type="checkbox" name="remember" onChange={handleChange} checked={values.remember} />{' '}
                              Remember me
                            </Label>
                          </FormGroup>
                          <LoadingButton loading={this.state.PENDING} disabled={this.state.PENDING} type="submit" color="c-primary" size="md" block>
                            Sign in
                          </LoadingButton>
                        </Form>
                      )}
                    />
                    <IE11Warning />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
          <NotificationAlert ref="notification" />
      </Fragment>
    );
  }
}


export default Login;
