import React, { Component } from 'react'
import NavBar from '../../common/NavBar';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import UsersTable from './UsersTable';
import Footer from '../../common/Footer';
import { getMe, getUsers, removeUser, updateUser, requestPasswordChange, getApplications, getClients } from "../../../api/users";
import { CurrentUserContext } from '../../../context/current-user-context';
import { DebounceInput } from 'react-debounce-input';
import NotificationAlert from 'react-notification-alert';
import {
  decodeToken,
  changeAppName,
  sortApplicationsByDisplayName,
  renderApplicationsWithDisplayName
} from "../../../utils/helpers";
import { initializeClientTheme, setClientTheme } from "../../../utils/clientTheme";

class Users extends Component {
  constructor(props) {
    super(props);
    initializeClientTheme();

    const apps = decodeToken().applications
      .filter(app => app.role === 'appAdmin')
      .map(a => a.application)
      .sort();

    this.state = {
      users: [],
      availableApplications: apps ? apps : [],
      allApplications: [],
      allClients: [],
      pagination: null,
      limit: 10,
      selectedUser: null,
      isUserSettingsModalOpen: false,
      isRemoveUserOpen: false,
      searchText: '',
      PENDING: false,
      PENDING_REMOVE_USER: false,
      PENDING_EDIT_USER_SETTINGS: false,
      PENDING_RESET_PASSWORD: false,
      openedDropdown: '',
      appFilter: '',
      clientFilter: ''
    };
  }

  componentDidMount() {
    getMe().then(res => {
      this.context.setCurrentUser(res.data.data);
      setClientTheme(res.data.data.client);
    });

    getApplications(false).then((res) => {
      this.setState({ allApplications: res.data.data });
    });

    getClients(false).then((res) => {
      this.setState({ allClients: res.data.data });
      this.context.setClients(res.data.data);
    });

    const { num } = this.props.match.params;
    this.loadUsers(num ? ((parseInt(num) - 1) * this.state.limit) : 0);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.num !== this.props.match.params.num) {
      this.loadUsers((parseInt(this.props.match.params.num) - 1) * this.state.limit, this.state.searchText, this.state.clientFilter, this.state.appFilter);
    }
  }

  triggerNotification = (message) => {
    this.refs.notification.notificationAlert({place: 'tr', message, type:'danger', autoDismiss: 5});
  };

  toggleRemoveUserModal = () => {
    this.setState(prevState => ({isRemoveUserOpen: !prevState.isRemoveUserOpen }));
  };

  toggleUserSettingsModal = () => {
    this.setState(prevState => ({isUserSettingsModalOpen: !prevState.isUserSettingsModalOpen }));
  };

  toggleOpenedDropdown = (value) => {
    this.setState({openedDropdown: value})
  };

  removeUser = () => {
    if (this.state.PENDING_REMOVE_USER) return;

    this.setState({ PENDING_REMOVE_USER: true });

    removeUser(this.state.selectedUser.id)
    .then(() => {
      this.setState({ PENDING_REMOVE_USER: false });
      window.location.reload();
    }).catch(() => {
      this.setState({ PENDING_REMOVE_USER: false });
      this.triggerNotification('Could not remove this user');
    })
  };

  editUserSettings = (body) => {
    if (this.state.PENDING_EDIT_USER_SETTINGS) return;

    this.setState({ PENDING_EDIT_USER_SETTINGS: true });

    updateUser(this.state.selectedUser.id, body)
    .then(() => {
      this.setState({ PENDING_EDIT_USER_SETTINGS: false });
      window.location.reload();
    }).catch(() => {
      this.setState({ PENDING_EDIT_USER_SETTINGS: false });
      this.triggerNotification('Could not edit this user');
    })
  };

  resetPassword = (email) => {
    if (this.state.PENDING_RESET_PASSWORD) return;

    this.setState({ PENDING_RESET_PASSWORD: true });

    requestPasswordChange(email)
    .then(() => {
      this.setState({ isUserSettingsModalOpen: false, PENDING_RESET_PASSWORD: false });
    }).catch(() => {
      this.setState({ PENDING_RESET_PASSWORD: false });
      this.triggerNotification('Could not reset password for this user');
    })
  };

  loadUsers = (offset = 0, searchText = '', client = '', appName ) => {
    this.setState({ PENDING: true });

    getUsers(offset, searchText, client, appName).then(({ data: { data, meta: { pagination } } }) => {
      this.setState({ users: data, pagination, PENDING: false });
    }).catch(() => {
      this.setState({ PENDING: false });
      this.triggerNotification('Could not load users');
    })
  };

  setSelectedUser = (user) => {
    this.setState({ selectedUser: user });
  };

  handleSearch = (e) => {
    const searchText = e.target.value;

    this.setState({ searchText: searchText });

    if (searchText.length === 0) {
      this.loadUsers(0);
      this.props.history.push('/users/page/1');
    } else if (searchText.length > 1) {
      this.loadUsers(0, searchText, this.state.clientFilter, this.state.appFilter);
      this.props.history.push('/users/page/1');
    }
  };

  handleFilterByApp = (appName) => {
    this.setState({ appFilter: appName });
    appName === '' ? this.loadUsers(0, this.state.searchText, this.state.clientFilter) : this.loadUsers(0, this.state.searchText,  this.state.clientFilter, appName);
    this.props.history.push('/users/page/1');
  };

  handleFilterByClient = (client) => {
    this.setState({ clientFilter: client.client || '', availableApplications: client === '' ? this.state.allApplications : client.applications, appFilter: '' });
    this.loadUsers(0, this.state.searchText, client.client);
    this.props.history.push('/users/page/1');
  };

  renderClientApps = () => {
    switch (true) {
      case this.state.selectedUser === null:
        return [];
      case this.state.selectedUser && this.state.selectedUser.client === 'SystemAdministration':
        return this.state.allClients.reduce((acc, curr) => [...acc, ...curr.applications], []);
      default:
        return this.state.allClients.find(client => client.client === this.state.selectedUser.client).applications
    }
  };

  render() {
    const applicationsWithDisplayName = renderApplicationsWithDisplayName(this.state.availableApplications);
    const sortedApplications = sortApplicationsByDisplayName(applicationsWithDisplayName);

    return (
      <>
        <div className="wrapper bg-dashboard">
          <NavBar />
          <Container className="py-4">
            <div className="py-2 table-row">
              <div className="d-lg-flex align-items-center">
                <h2>Users</h2>
                <Form inline className="ml-lg-3" onSubmit={e => { e.preventDefault() }}>
                  <DebounceInput
                    className="form-control"
                    type="search"
                    placeholder="Search users"
                    debounceTimeout={400}
                    onChange={(e) => this.handleSearch(e)}
                  />
                </Form>
              </div>
              <div className="d-lg-flex align-items-center mt-4 table-buttons">
                <div className="d-flex">
                <Dropdown isOpen={this.state.openedDropdown === 'app'} toggle={() => {
                  this.toggleOpenedDropdown(this.state.openedDropdown === 'app' ? '' : 'app')
                }}>
                  <DropdownToggle caret color="c-primary">
                    Filter by { changeAppName(this.state.appFilter) || 'app'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilterByApp('')}
                                  active={this.state.appFilter === ''}
                                  className="c-primary">All</DropdownItem>
                    {sortedApplications.map(application => (
                        <DropdownItem onClick={() => this.handleFilterByApp(application.originalName)}
                                      active={this.state.appFilter === application.originalName}
                                      className="c-primary">
                          {application.displayName}
                        </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                {this.context.currentUser.role === 'admin' ? <Dropdown isOpen={this.state.openedDropdown === 'client'} toggle={() => {
                  this.toggleOpenedDropdown(this.state.openedDropdown === 'client' ? '' : 'client')
                }}>
                  <DropdownToggle caret color="c-primary" className="ml-2">
                    Filter by {this.state.clientFilter || 'client'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.handleFilterByClient('')}
                                  active={this.state.clientFilter === '' || this.state.clientFilter === ''}>
                      All
                    </DropdownItem>
                    {this.state.allClients.map(client => (
                        <DropdownItem onClick={() => this.handleFilterByClient(client)}
                                      active={this.state.clientFilter === client.client}>
                          {client.client}
                        </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown> : null }
                </div>
              <div className="table-form-buttons">
                <Link to={{
                  pathname: '/users/add',
                  state: this.state.availableApplications
                }}>
                  <Button color="c-primary" className="mb-2 mr-2 mr-sm-0 mb-lg-0 ml-lg-2">
                    <i className="fas fa-file-import" /> <span>Add New User</span>
                  </Button>
                </Link>
                <Link to="/users/import" >
                  <Button color="c-primary" className="mb-2 mb-lg-0 ml-2">
                    <i className="fas fa-plus-circle" /> <span>Import Users From File</span>
                  </Button>
                </Link>
              </div>
              </div>
            </div>
          </Container>
          <UsersTable
            users={this.state.users}
            availableApplications={this.state.availableApplications}
            selectedUser={this.state.selectedUser}
            setSelectedUser={this.setSelectedUser}
            isUserSettingsModalOpen={this.state.isUserSettingsModalOpen}
            toggleUserSettingsModal={this.toggleUserSettingsModal}
            editUserSettings={this.editUserSettings}
            isRemoveUserOpen={this.state.isRemoveUserOpen}
            toggleRemoveUserModal={this.toggleRemoveUserModal}
            removeUser={this.removeUser}
            resetPassword={this.resetPassword}
            pagination={this.state.pagination}
            PENDING={this.state.PENDING}
            PENDING_REMOVE_USER={this.state.PENDING_REMOVE_USER}
            PENDING_EDIT_USER_SETTINGS={this.state.PENDING_EDIT_USER_SETTINGS}
            PENDING_RESET_PASSWORD={this.state.PENDING_RESET_PASSWORD}
            allClients={this.state.allClients}
            availableClientApps={this.renderClientApps()}
          />
        </div>
        <NotificationAlert ref="notification" />
        <Footer />
      </>
    )
  }

}

Users.contextType = CurrentUserContext;

export default Users;
