import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import LoadingButton from '../../common/LoadingButton';
import * as Yup from 'yup';
import { checkUsername } from '../../../api/users';
import { CurrentUserContext } from '../../../context/current-user-context';
import { withRouter } from "react-router-dom";

const CheckUsernameForm = ({ setStep, history }) => {
    const context = useContext(CurrentUserContext);

    const [isUserFromAnotherClient, setIsUserFromAnotherClient] = useState(false);

    const [SUBMIT_PENDING, setSUBMIT_PENDING] = useState(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
  });

  const adminUserApplications = context.currentUser.userApplications;

 useEffect(() => {
     const isContextUserEmpty = !Object.keys(context.currentUser).length;

     if (isContextUserEmpty) {
         history.push("/users")
     }
 }, []);

  const onSubmitHandler = (values, actions) => {
    if(SUBMIT_PENDING) return;
    setSUBMIT_PENDING(true);

    checkUsername(values.username)
      .then((res) => {

        let userModel = null;
        let filteredApplications = [];

        const userExists = res && res.data && res.data.data && res.data.data.userExists;

        if (userExists && context.currentUser.role !== 'admin' && res.data.data.user.client !== context.currentUser.client) {
            setIsUserFromAnotherClient(true);
            actions.setErrors({ username: 'Cannot add user. Please contact with System Administrator.'});
            setSUBMIT_PENDING(false);
            return;
        }
       else if (userExists) {
           userModel = res.data.data.user;

         filteredApplications = userModel.userApplications.filter((userApp) => {
                return adminUserApplications.some((el) => {
                    return el.application === userApp.application && el.role === 'appAdmin';
                });
            });

            userModel.userApplications = filteredApplications;
        }

        setSUBMIT_PENDING(false);
        setStep(2, userModel, userModel ? null : values.username);
      })
      .catch((err) => {
        setSUBMIT_PENDING(false);
      });
  };

  return (
    <Formik
      initialValues={{ username: '' }}
      onSubmit={(values, actions) => onSubmitHandler(values, actions)}
      validationSchema={validationSchema}
      validateOnChange={false}
      render={({ handleSubmit, handleChange, values, errors }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-0">
            <Label for="username">E-mail <span className="text-danger">*</span></Label>
            <Input type="email" id="username" placeholder="E-mail..." name="username" onChange={handleChange} value={values.username} required/>
            {errors.username ? <div className="small text-danger p-1">{errors.username}</div> : <div style={{height: '19px'}} />}
          </FormGroup>
          <LoadingButton loading={SUBMIT_PENDING} disabled={SUBMIT_PENDING} color="c-primary" className="mt-3" type="submit">Add User</LoadingButton>
        </Form>
      )}
    />
  )
};

export default withRouter(CheckUsernameForm);
