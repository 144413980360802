import React from 'react';
import { Container, Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ContentCard from '../../../common/ContentCard';
import CustomPagination from '../../../common/Pagination';
import RemoveUsersModal from './RemoveUserModal';
import UserSettingsModal from './UserSettingsModal';
import { TableDataRole } from './TableDataRow';
import StatusRow from './StatusRow';

class UsersTable extends React.Component {
  renderUsers = users => {
    return users.map((user, index) => (
      <tr key={user.id} id={user.id}>
        <td>{index + 1}</td>
        <td>{user.username}</td>
        <td>{user.firstName}{user.lastName && ' '}{user.lastName}</td>
        <td>{user.company ? user.company : '-'}</td>
        <td className="text-center">{new Date(user.createdAt).toLocaleDateString('swe')}</td>
        <StatusRow user={user} />
        <TableDataRole user={user} />
        <td className="text-center">
          <button onClick={() => { this.props.toggleUserSettingsModal(); this.props.setSelectedUser(user) }} className="mr-2 clear-button-styles-primary cursor-pointer" title="Settings" data-toggle="tooltip">
            <i className="fas fa-cog fa-lg" />
          </button>
          <button onClick={() => { this.props.toggleRemoveUserModal(user); this.props.setSelectedUser(user) }} className="clear-button-styles-primary cursor-pointer" title="Delete" data-toggle="tooltip">
            <i className="fas fa-minus-circle fa-lg" />
          </button>
        </td>
      </tr>
    ));
  };

  render() {
    const { users, availableApplications, availableClientApps, allClients, selectedUser, isUserSettingsModalOpen, toggleUserSettingsModal, editUserSettings, isRemoveUserOpen, toggleRemoveUserModal, removeUser, resetPassword, pagination, PENDING, PENDING_REMOVE_USER, PENDING_EDIT_USER_SETTINGS, PENDING_RESET_PASSWORD } = this.props;

    return (
      <Container className="pb-5">
        <ContentCard>
          {PENDING ? (
            <div className="text-center my-5"><i className="fas fa-spinner fa-spin fa-lg mr-2" /></div>
          ) : (
              <div>
                <Table className="mb-0 home-text" style={{minWidth: '800px'}} hover responsive>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th className="text-center">Date Created</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Role</th>
                    <th className="text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    {users.length ?
                      this.renderUsers(users) :
                      <tr>
                        <td colSpan="8" className="text-center py-5">No users found</td>
                      </tr>
                    }
                  </tbody>
                </Table>
                <CustomPagination pagination={pagination} />
              </div>
            )}
        </ContentCard>
        <RemoveUsersModal
          isOpen={isRemoveUserOpen}
          toggle={toggleRemoveUserModal}
          action={removeUser}
          pending={PENDING_REMOVE_USER}
          selectedUser={selectedUser} />
        <UserSettingsModal
          isOpen={isUserSettingsModalOpen}
          toggle={toggleUserSettingsModal}
          editUserSettings={editUserSettings}
          resetPassword={resetPassword}
          PENDING_EDIT_USER_SETTINGS={PENDING_EDIT_USER_SETTINGS}
          PENDING_RESET_PASSWORD={PENDING_RESET_PASSWORD}
          selectedUser={selectedUser}
          availableApplications={availableApplications}
          availableClients={allClients}
          availableClientApps={availableClientApps}
        />
      </Container>
    );
  }
}

export default withRouter(UsersTable);
