import request from './request';

export const login = body => request({
  url: '/api/users/login',
  method: 'POST',
  headers: {},
  data: body,
});

export const refreshToken = body => request({
  url: '/api/users/authorize',
  method: 'POST',
  headers: {},
  data: body,
});