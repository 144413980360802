import React from 'react';
import { Card, CardBody } from 'reactstrap';

export default function ContentCard(props) {
  return (
    <div>
      <Card className="card-primary">
        <CardBody>{props.children}</CardBody>
      </Card>
    </div>
  );
}