import React, { Component, Fragment } from 'react'
import NavBar from '../../common/NavBar';
import Footer from '../../common/Footer';
import ContentCard from '../../common/ContentCard';
import { Container, Row, Col, Button } from 'reactstrap';
import AddUserForm from './AddUserForm';
import CheckUsernameForm from './CheckUsernameForm';
export default class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PENDING: false,
      step: 1,
      user: null,
      freshEmail: null
    }
  }

  setStep = (step, user, freshEmail) => {
    this.setState({ step, user, freshEmail });
  };

  render() {
    return (
      <Fragment>
      <div className="wrapper bg-dashboard">
        <NavBar />
        <Container>
          <Col xs="6">
            <Row className="py-4">
              <Col sm="8">
                <h2>
                  Add new User
              </h2>
              </Col>
              <Col sm="4" className="text-right">
                <Button className="mx-2" color="c-primary" onClick={() => this.props.history.goBack()}>
                  <i className="fas fa-arrow-left"></i> <span>Back</span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <ContentCard>
                  {this.state.step === 1 && <CheckUsernameForm setStep={this.setStep} />}
                  {this.state.step === 2 && <AddUserForm user={this.state.user} freshEmail={this.state.freshEmail} history={this.props.history} availableApplications={this.props.location.state} />}
                </ContentCard>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
      <Footer />
      </Fragment>
    )
  }
}

