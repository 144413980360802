import React, {useState} from 'react';
import { Tooltip} from "reactstrap";
import { FormattedDate , FormattedTime} from 'react-intl';

const StatusRow = ({ user }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const toggle = () => setIsTooltipOpen(!isTooltipOpen);

    return (
        <td className="text-center" id={`tooltipStatusTarget${user.id}`}>
            {<i title={`${user.isDisabled ? "Disabled" : "Enabled"}`} data-toggle="tooltip" className={`fas fa-circle fa-lg text-${user.isDisabled ? "danger" : "success"}`} />}
            <div>
                <Tooltip style={{ maxWidth: 300, zIndex: 2000, display: 'flex' }} delay={0} placement="top" isOpen={isTooltipOpen} toggle={toggle} target={`tooltipStatusTarget${user.id}`} modifiers={{preventOverflow: {boundariesElement: "viewport"}}}>
                    <p className="pr-1">Last activity:</p>
                    {user.lastActivityAt !== null ? (
                        <>
                            {new Date(user.lastActivityAt).toLocaleDateString('swe')}
                            {' '}
                            <FormattedTime value={user.lastActivityAt} />
                        </>
                    ) : 'No activity recorded'}
                </Tooltip>
            </div>
        </td>
    )
};

export default StatusRow;
