import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAdmin } from '../../../utils/isAdmin';
import { isAuthenticated } from '../../../utils/isAuthenticated';
import Cookie from 'js-cookie';


export default function AdminOnlyRoute({ component: Component, ...rest }) {

  const clearAndRedirect = () => {
    Cookie.remove('access_token');
    Cookie.remove('refresh_token');
    window.location.assign(`${process.env.REACT_APP_WEB_APP_URL}`)
    return null;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          isAdmin() ? (
            <Component {...props} />
          ) : (
            clearAndRedirect()
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}