import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Alert,
  Label,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { setPassword, validateToken } from '../../../api/users';
import Cookie from 'js-cookie';
import LoadingButton from '../../common/LoadingButton';
import queryString from 'query-string';
import Footer from '../../common/Footer';
import { setClientTheme, initializeDefaultTheme } from "../../../utils/clientTheme";

class SetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PENDING: false,
      CHECKING: false,
      TOKEN_ERROR: false,
      PASSWORD_ERROR: false,
      privacyPolicy: false,
    };
  }

  componentDidMount() {
    const { client, app, token } = queryString.parse(this.props.location.search);

    this.setState({
      CHECKING: true,
      TOKEN_ERROR: false,
      client: client,
      application: app,
      token: token,
    });

    if(!!client){
      setClientTheme(client);
    }
    else {
      initializeDefaultTheme();
    }
    

    validateToken(token)
      .then((res) => {
        if (!res.data.data) {
          this.handleAppRedirection(app);
        } else {
          this.setState({ CHECKING: false });
        }
      })
      .catch(() => {
        this.setState({ CHECKING: false, TOKEN_ERROR: true });
      });
  }

  redirectUser = (user) => {
    if (user.role === 'admin' || user.role === 'appAdmin') {
      this.props.history.push('/users');
    } else {
      if (user.applications.length) {
        this.handleAppRedirection(this.state.application);
      }
    }
  };

  onSubmitHandler = (value) => {
    if (this.state.PENDING) return;
    this.setState({ PENDING: true, PASSWORD_ERROR: false });

    setPassword({ token: this.state.token, newPassword: value.password })
      .then((response) => {
        const { user } = response.data.data;

        if (user.role === 'admin' || user.role === 'appAdmin') {
          Cookie.set('access_token', response.data.data.jwt);
          Cookie.set('refresh_token', response.data.data.refreshToken);
        }

        this.setState({ PENDING: false });
        this.redirectUser(user);
      })
      .catch(() => {
        this.setState({ PASSWORD_ERROR: true, PENDING: false });
      });
  };

  handleAppRedirection = (app) => {
    switch (app) {
      case 'MIB':
        window.location.assign(`${process.env.REACT_APP_WEB_APP_URL}`);
        break;
      case 'FuelLine':
        window.location.assign(
          `${process.env.REACT_APP_FUEL_LINE_WEB_APP_URL}`
        );
        break;
      case 'PureBallast':
        window.location.assign(
          `${process.env.REACT_APP_PUREBALLAST_WEB_APP_URL}`
        );
        break;
      case 'PureBallastServices':
        window.location.assign(
          `${process.env.REACT_APP_PUREBALLAST_SERVICES_WEB_APP_URL}`
        );
        break;
      case 'SolarPower':
        window.location.assign(
          `${process.env.REACT_APP_SOLARPOWER_WEB_APP_URL}`
        );
        break;
      case 'SPCompetitorInsight':
        window.location.assign(
          `${process.env.REACT_APP_SP_COMPETITOR_INSIGHT_APP_URL}`
        );
        break;
      case 'CarePlus':
        window.location.assign(`${process.env.REACT_APP_CARE_PLUS_URL}`);
        break;
      case 'MarineDigitalServicesTool':
        window.location.assign(
          `${process.env.REACT_APP_MARINE_DIGITAL_SERVICES_TOOL_APP_URL}`
        );
        break;
      case 'PureNOxService':
        window.location.assign(
          `${process.env.REACT_APP_PURE_NO_X_SERVICE_WEB_APP_URL}`
        );
        break;
      case 'MarineOverview':
        window.location.assign(
          `${process.env.REACT_APP_MARINE_OVERVIEW_WEB_APP_URL}`
        );
        break;
      case 'SeparatorVsFilterOperation':
        window.location.assign(
          `${process.env.REACT_APP_SEPARATOR_VS_FILTER_OPERATION_APP_URL}`
        );
        break;
      case 'MuntersSprayDrying':
        window.location.assign(
          `${process.env.REACT_APP_MUNTERS_SPRAY_DRYING_APP_URL_APP_URL}`
        );
        break;
      case 'MuntersHeatPump':
        window.location.assign(
          `${process.env.REACT_APP_MUNTERS_HEAT_PUMP_APP_URL}`
        );
        break;
      case 'MuntersGreenDry':
        window.location.assign(
          `${process.env.REACT_APP_MUNTERS_GREEN_DRY_APP_URL}`
        );
        break;

      case 'PureBallastDrydock':
          window.location.assign(
            `${process.env.REACT_APP_PURE_BALLAST_DRYDOCK_APP_URL}`
          );
          break;

      default:
        this.props.history.push('/login');
        break;
    }
  };

  handlePrivacyPolicyChange = () =>
    this.setState({ privacyPolicy: !this.state.privacyPolicy });

  validationSchema = Yup.object().shape({
    password: Yup.string().matches(
      /^((?=.*[a-z])(?=.*[A-Z])(?=.*[ !@#$%^&*\[\]{}""'\\|:;/.,<>~*\-\+=_ \d]).{8,})$/
    ),
    repeatPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  renderFormik = () => (
    <Formik
      initialValues={{ password: '', repeatPassword: '' }}
      onSubmit={(values, actions) => this.onSubmitHandler(values, actions)}
      validationSchema={this.validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      render={({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-1" style={{ paddingBottom: 19 }}>
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              name="password"
              id="passwordInput"
              placeholder="Password..."
              required
              autoFocus
            />
          </FormGroup>
          <FormGroup className="mb-1">
            <Input
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repeatPassword}
              name="repeatPassword"
              type="password"
              id="repeatPasswordInput"
              placeholder="Repeat password..."
              required
            />
            {errors.password && (
              <div className="small text-danger">
                Password requirements:
                <ul>
                  <li>minimum 8 characters</li>
                  <li>one lowercase character</li>
                  <li>one uppercase character</li>
                  <li> one number or special character</li>
                </ul>
              </div>
            )}
            {!errors.password && errors.repeatPassword && (
              <div className="small text-danger">{errors.repeatPassword}</div>
            )}
            {!(errors.password || errors.repeatPassword) && (
              <div style={{ height: 19 }} />
            )}
          </FormGroup>
          <FormGroup check className="mb-3">
            <Label check>
              <Input
                type="checkbox"
                value={this.state.privacyPolicy}
                onChange={this.handlePrivacyPolicyChange}
              />
              I accept the{' '}
              <a
                href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                target="_blank"
              >
                privacy policy
              </a>
              .
            </Label>
          </FormGroup>
          <LoadingButton
            loading={this.state.PENDING}
            disabled={this.state.PENDING || !this.state.privacyPolicy}
            type="submit"
            color="c-primary"
            size="md"
            block
          >
            Set password
          </LoadingButton>
        </Form>
      )}
    />
  );

  render() {
    return (
      <Fragment>
        <div className="wrapper bg-ship">
          <Container>
            <Row>
              <Col sm="9" md="7" lg="4" className="mx-auto mt-5">
                <Card className="card-signin my-5">
                  <CardBody>
                    {this.state.CHECKING ? (
                      <div className="text-center my-5">
                        <i className="fas fa-spinner fa-spin fa-lg mr-2" />
                      </div>
                    ) : this.state.TOKEN_ERROR ? (
                      <Alert color="danger" className="mb-0">
                        An error occurred, please try again later
                      </Alert>
                    ) : (
                      <>
                        <h2 className="card-title">Set password</h2>
                        {this.state.PASSWORD_ERROR ? (
                          <Alert color="danger">
                            Setting new password failed
                          </Alert>
                        ) : null}
                        {this.renderFormik()}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default SetPassword;
