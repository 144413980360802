import React, { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingButton from '../../../common/LoadingButton';

export default function RemoveUserModal({ isOpen, toggle, className, action, selectedUser, pending }) {
  const toggleMethod = () => {
    toggle();
  };

  const renderMessageForEnabledUsers = () => (
    <Fragment>
      <ModalBody>
        User has to be <strong>disabled</strong> first in order to delete.
      </ModalBody>
      <ModalFooter>
        <Button color="c-secondary" onClick={toggleMethod}>Go Back</Button>
      </ModalFooter>
    </Fragment>
  );

  const renderMessageForDisabledUsers = () => (
    <Fragment>
      <ModalBody>
        Are you sure you want remove user <strong>{selectedUser && selectedUser.username}</strong>?
      </ModalBody>
      <ModalFooter>
        <LoadingButton loading={pending} disabled={pending} color="danger" onClick={action}>Remove</LoadingButton>{' '}
        <Button color="c-secondary" onClick={toggleMethod}>Cancel</Button>
      </ModalFooter>
    </Fragment>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggleMethod} className={className}>
      <ModalHeader toggle={toggleMethod}>Remove user</ModalHeader>
      {(selectedUser && selectedUser.isDisabled) ? renderMessageForDisabledUsers() : renderMessageForEnabledUsers()}
    </Modal>
  )
}