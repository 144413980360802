import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { translateRoleName, changeAppName } from '../../../utils/helpers';

const AppCheckbox = ({ app, roles, handler, initialValue }) => {
  const [availableRoles, setRoles] = useState([]);
  const [selectedRole, selectRole] = useState("");
  const [active, setActive] = useState(!!initialValue);

  useEffect(() => {
    setRoles(roles);

    if (initialValue && initialValue.role) {
      const index = roles.findIndex(entry => entry === initialValue.role);
      if (index > -1) {
        selectRole(roles[index]);
        return;
      }
    }

    selectRole(roles[0]);
  }, [roles]);

  useEffect(() => {
    handler(active, { application: app, role: selectedRole });
  }, [selectedRole, active]);

  const updateRole = (e) => {
    selectRole(e.target.value);
  };

  const updateActive = (e) => {
    setActive(e.target.checked);
  };

  return (
    <FormGroup check key={app}>
      <Label className="label-flex" check>
        <Input defaultChecked={active} disabled={!!initialValue} type="checkbox" name={app} value={active} onChange={updateActive} />
        <span className="mr-3" style={{ minWidth: '170px' }}>{changeAppName(app)}</span>

        {active && <Input disabled={!!initialValue} type="select" value={selectedRole} onChange={updateRole} style={{ maxWidth: '240px' }}>
          {
            availableRoles.map(role => {
              return (
                <option key={role} value={role}>
                  {translateRoleName(role)}
                </option>
              )
            })
          }
        </Input>}
      </Label>
    </FormGroup>
  )
};

export default AppCheckbox;
