import React, { useState ,useContext } from 'react';
import { Navbar, NavbarToggler, Collapse, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Cookie from 'js-cookie';

import { CurrentUserContext } from '../../../context/current-user-context';

const NavBar = (props) => {
  const context = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    Cookie.remove('access_token');
    Cookie.remove('refresh_token');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    props.history.push('/login');
  };

  const renderClientName = () => {
    switch (context.currentUser.client) {
      case 'SystemAdministration':
        return 'Administration';

      case 'Alpaxa':
        return 'Alpaxa';

      case 'AlfaLaval':
        return 'Alfa Laval';

      case 'Munters':
        return 'Munters';

      default:
        return null;
    }
  };

  return (
    <Navbar light expand="md" className="bg-nav">
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={isOpen} navbar>
        <div className="w-100 d-flex justify-content-between">
          <div className="d-flex align-items-center font-weight-bold">{renderClientName()}</div>
          <div className="d-flex">
            <div className="d-flex align-items-center">
                {context.currentUser.username}
            </div>
            <div className="ml-4">
              <Button color="c-primary" onClick={handleLogout}>
                <i className="fas fa-power-off"></i> Sign out
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </Navbar>
  );
};

export default withRouter(NavBar);
