import Cookie from 'js-cookie'

export const initializeDefaultTheme = () =>{
    var clientConfig=getStoredConfiguration();
    if (!clientConfig) {
        clientConfig= getDefaultThemeConfig();
    }
    applyThemeSettings(clientConfig);
}

export const initializeClientTheme = () => {
    var clientConfig=getStoredConfiguration();
    if (!clientConfig) {
        clientConfig= nonClientTheme();
    }
    applyThemeSettings(clientConfig);
};

export const setClientTheme = (client) => {
    var clientConfig;
    switch(client) {
        case 'Munters':
            clientConfig = getMuntersThemeConfig();
            break;
            
        default:
            clientConfig = getDefaultThemeConfig();
            break;
    }
    Cookie.set('client_config',JSON.stringify(clientConfig));
    sessionStorage.setItem('client_config', JSON.stringify(clientConfig));
    applyThemeSettings(clientConfig)
};

export const resetClientTheme = () =>{
    Cookie.remove('client_config');
    sessionStorage.removeItem('client_config');
}

const getStoredConfiguration = () => {
    if (!!sessionStorage.getItem('client_config')) {
        return JSON.parse(sessionStorage.getItem('client_config'))
    }
    else if(!!Cookie.get('client_config')) {
        return JSON.parse(Cookie.get('client_config'))
    }
    else
    {
        return null;
    }
}

const nonClientTheme = () =>
    {
        // default configurations
        return {
            c_Text: '#212529',
            bg_primary: '#FFFFFF',
            bg_secondary: '#FFFFFF',
            font_family: 'Roboto',
            font_family_fallback:'Helvetica,sans-serif',
            c_primary: '#FFFFFF',
            c_primary_active: '#161558',
            c_primary_text: '#FFFFFF',
            c_secondary: '#FFFFFF',
            c_secondary_active: '#847851',
            c_secondary_text: '#FFFFFF'
          }
    };

const getDefaultThemeConfig = () =>
{
    // default configurations
    return {
        c_Text: '#212529',
        bg_primary: '#FFFFFF',
        bg_secondary: '#C9CEDE',
        font_family: 'Roboto',
        font_family_fallback:'Helvetica,sans-serif',
        c_primary: '#1e1c77',
        c_primary_active: '#161558',
        c_primary_text: '#FFFFFF',
        c_secondary: '#8c7f70',
        c_secondary_active: '#847851',
        c_secondary_text: '#FFFFFF'
      }
};

const getMuntersThemeConfig = () =>
{
    return {
        c_Text: '#373737',
        bg_primary: '#FFFFFF',
        bg_secondary: '#F6F1E7',
        font_family: "'nexa-text-web'",
        font_family_fallback:"'Segoe UI',Roboto,'Helvetica Neue',sans-serif",
        c_primary: '#2B89ED',
        c_primary_active: '#2554A1',
        c_primary_text: '#FFFFFF',
        c_secondary: '#828282',
        c_secondary_active: '#B3B3B3',
        c_secondary_text: '#FFFFFF'
      };
};

const applyThemeSettings = (themeConfig) =>{
    document.documentElement.style.setProperty('--c-text',themeConfig.c_Text);
    document.documentElement.style.setProperty('--bg-primary', themeConfig.bg_primary);
    document.documentElement.style.setProperty('--bg-secondary',themeConfig.bg_secondary);
    document.documentElement.style.setProperty('--font-family-base',themeConfig.font_family);
    document.documentElement.style.setProperty('--font-family-fallback',themeConfig.font_family_fallback);

    document.documentElement.style.setProperty('--c-primary',themeConfig.c_primary);
    document.documentElement.style.setProperty('--c-primary-active',themeConfig.c_primary_active);
    document.documentElement.style.setProperty('--c-primary-text',themeConfig.c_primary_text);

    document.documentElement.style.setProperty('--c-secondary',themeConfig.c_secondary);
    document.documentElement.style.setProperty('--c-secondary-active',themeConfig.c_secondary_active);
    document.documentElement.style.setProperty('--c-secondary-text',themeConfig.c_secondary_text);
};