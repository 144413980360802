import React, { useState, useContext } from 'react';
import { Tooltip } from 'reactstrap';
import { CurrentUserContext } from '../../../../context/current-user-context';
import { changeAppName, translateRoleName } from '../../../../utils/helpers';

export const TableDataRole = ({ user }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const context = useContext(CurrentUserContext);

    const toggle = () => setIsTooltipOpen(!isTooltipOpen);

    return (
        <td className='text-center' id={`tooltipTarget${user.id}`}>
            <i className='fas fa-info-circle fa-lg' />
            <div>
                <Tooltip
                    style={{ maxWidth: 300, zIndex: 2000 }}
                    delay={0}
                    placement='top'
                    isOpen={isTooltipOpen}
                    toggle={toggle}
                    target={`tooltipTarget${user.id}`}
                    modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                >
                    {user.userApplications.length
                        ? user.userApplications.map((application) => (
                              <p className='m-0' key={application.application}>
                                  {changeAppName(application.application)}: {translateRoleName(application.role)}
                              </p>
                          ))
                        : 'None'}

                    {context.currentUser.role === 'admin' && (
                        <p className='pt-2 mb-1 text-capitalize'>System: {user.role} </p>
                    )}
                </Tooltip>
            </div>
        </td>
    );
};
