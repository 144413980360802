import React, {useContext, useState} from 'react';
import { Form, FormGroup, FormText, Label, Input, Alert, Button } from 'reactstrap';
import { Formik } from 'formik';
import { importFile } from '../../../api/users';
import LoadingButton from '../../common/LoadingButton';
import AppsModal from './AppsModal';
import { decodeToken } from '../../../utils/helpers';
import { CurrentUserContext } from '../../../context/current-user-context';

const ImportUsersForm = ({ history }) => {
  const context = useContext(CurrentUserContext);

  const [PENDING, setPENDING] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApps] = useState(new Set());
  const [selectedClient, setSelectedClient] = useState(context.clients.length > 0 ? context.clients[0].client : 'SystemAdministration');
  const [userData] = useState(decodeToken());
  const [isChecked, setIsChecked] = useState(false);

  const hasAdminRole = context.currentUser.role === 'admin';

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) selectedApps.clear();
  };

  const handleClientChange = (e) => {
    selectedApps.clear();
    setSelectedClient(e.target.value);
  };

  const onSubmitHandler = (values, setFieldError) => {
    if(PENDING) return;
    setPENDING(true);

    if (userData.applications.length === 1) {
      selectedApps.add(userData.applications[0]);
    }

    const fileNameArr = values.file.name.split('.');
    const fileNameLength = fileNameArr.length;
    const format = fileNameArr[fileNameLength - 1];

    const fileBlob = new Blob([values.file], {type: values.file.type});
    const appsArr = Array.from(selectedApps);
    const appsStr = appsArr.join(',');

    importFile(format, fileBlob, appsStr, selectedClient)
    .then(() => {
      history.push('/users');
    })
    .catch((err) => {
      if(err.status === 500){
        err = {data: {meta: {errors: [{code: "internal_server_error", reason: "Internal server error"}]}}}
      }
      setIsModalOpen(false);
      setFieldError('file', err);
      setPENDING(false);
    })
  };

  const renderErrors = (errors) => {
    return errors.data.meta.errors.map((error, index) => (
       <div key={index}>Error: {error.reason}</div>
    ))
  };

  const handleExampleFileDownload = () => {
    let anchor = document.createElement('a');

    anchor.setAttribute('href', `${process.env.PUBLIC_URL}/example-import.xlsx`);
    anchor.setAttribute('download','');
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode.removeChild(anchor);
  };

  const validateAppsSubmit = () => {
    selectedApps.size ? setIsChecked(true) : setIsChecked(false);
  };

  const handleCheckboxChange = (e, app) => {
    app = app === 'Adaptive Fuel Line' ? 'FuelLine' : app;

    console.log(app);

    if(e.target.checked) {
      selectedApps.add(app);
    } else {
      selectedApps.delete(app);
    }

    validateAppsSubmit();
  };

  return (
    <>
      <Formik
        initialValues={{ file: null }}
        onSubmit={(values, setFieldError) => onSubmitHandler(values, setFieldError)}
        render={({ handleSubmit, values, errors, setFieldValue, setFieldError }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="fileWithUsers">File</Label>
              {errors.file ? <Alert color="danger">{renderErrors(errors.file)}</Alert> : null}
              <Input
                type="file"
                accept=".xlsx"
                name="file"
                id="fileWithUsers"
                onChange={(e) => {
                  setFieldValue('file', e.currentTarget.files[0]);
                  e.currentTarget.files.length ? setIsFileUploaded(true) : setIsFileUploaded(false);
                }}
              />
              <FormText color="muted">Choose .xlsx file</FormText>
            </FormGroup>
            <div className="d-flex justify-content-between">
              {userData.applications.length > 1 || userData.applications.length === 0 ? (
              <LoadingButton
                loading={PENDING}
                disabled={PENDING || !isFileUploaded}
                color='c-primary'
                className="mt-1"
                onClick={() => {
                    toggle();
                    validateAppsSubmit();
                  }
                }
              >
                Send file
              </LoadingButton>)
              : (
              <LoadingButton
                loading={PENDING}
                disabled={PENDING || !isFileUploaded}
                color="c-primary"
                className="mt-1"
                type="submit"
                onClick={() => onSubmitHandler(values, setFieldError)}
              >
                Send file
              </LoadingButton>)}
              <Button color="c-primary" className="mt-1" onClick={handleExampleFileDownload}>Download example file</Button>
            </div>
            <AppsModal
              isOpen={isModalOpen}
              toggle={toggle}
              isPending={PENDING}
              isFileUploaded={isFileUploaded}
              isChecked={isChecked}
              onSubmitFn={() => onSubmitHandler(values, setFieldError)}
              applications={userData.applications}
              handleCheckboxChangeFn={handleCheckboxChange}
              clients={context.clients}
              selectedClient={selectedClient}
              handleClientChange={handleClientChange}
              hasAdminRole={hasAdminRole}
            />
          </Form>
        )}
      />
    </>
  )
};

export default ImportUsersForm;
