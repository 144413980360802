import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from '../components/views/Login';
import SetPassword from '../components/views/SetPassword';
import ResetPassword from '../components/views/ResetPassword';
import AdminOnlyRoute from '../components/common/AdminOnlyRoute';
import AddUser from '../components/views/AddUser';
import ImportUsers from '../components/views/ImportUsers';
import Users from './views/Users';
import { CurrentUserContextProvider } from '../context/current-user-context';
import { isAuthenticated } from '../utils/isAuthenticated';

export default function App() {
  return (
    <CurrentUserContextProvider>
      <Router>
        <Fragment>
          <Switch>
            <AdminOnlyRoute exact path="/" component={Users} />
            <AdminOnlyRoute exact path="/users" component={Users} />
            <AdminOnlyRoute exact path="/users/page/:num" component={Users} />
            <AdminOnlyRoute exact path="/users/add" component={AddUser} />
            <AdminOnlyRoute exact path="/users/import" component={ImportUsers} />
            <Route path="/login" render={({history}) => isAuthenticated() ? <Redirect to="/users" /> : <Login history={history}/>} />
            <Route path="/set-password" render={({history, location}) => isAuthenticated() ? <Redirect to="/users" /> : <SetPassword history={history} location={location}/>} />
            <Route path="/reset-password" render={({history}) => isAuthenticated() ? <Redirect to="/users" /> : <ResetPassword history={history}/>} />
            <Route render={() => <Redirect to="/users" />} />
          </Switch>
        </Fragment>
      </Router>
    </CurrentUserContextProvider>
  );
}
