import React from 'react';
import { Container } from 'reactstrap';
import { version } from '../../../../package.json';

export default function Footer() {
  return (
    <div className="footer">
      <Container>
        <div className="small">
          version {version}
        </div>
      </Container>
    </div>
  );
}