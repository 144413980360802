import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { translateRoleName, changeAppName, sortApplicationsByDisplayName } from '../../../../utils/helpers';

const UserApplications = ({ availableApplications, userApplications, availableRoles, update }) => {
    const [apps, setApps] = useState([]);

    const changeHandler = (e, index) => {
        const tempArray = [...apps];
        tempArray[index].active = e.target.checked;
        tempArray[index].role = e.target.checked ? availableRoles[0] : undefined;
        update(parseArray(tempArray));
    };

    const roleHandler = (e, index) => {
        const tempArray = [...apps];
        tempArray[index].role = e.target.value;
        update(parseArray(tempArray));
    };

    const parseArray = (arr) => {
        const parsedArray = [];
        arr.map((entry) => {
            if (entry.active) {
                parsedArray.push({
                    application: entry.application,
                    role: entry.role,
                });
            }
        });
        return parsedArray;
    };

    useEffect(() => {
        const appsArray = availableApplications.map((availableApp) => {
            const appModel = {
                application: availableApp,
            };

            userApplications.forEach((userApp) => {
                if (userApp.application === availableApp) {
                    appModel.active = false;
                    appModel.role = userApp.role;
                }
            });

            return {
                originalName: appModel.application,
                displayName: changeAppName(appModel.application),
                role: appModel.role,
                active: appModel.active,
            };
        });

        setApps(sortApplicationsByDisplayName(appsArray));
    }, [userApplications, availableApplications, availableRoles]);

    return (
        <div>
            {apps.map((app, index) => {
                return (
                    <FormGroup check key={app.originalName}>
                        <Label className='label-flex' check>
                            <Input
                                type='checkbox'
                                name={app.originalName}
                                defaultChecked={app.active}
                                onChange={(e) => {
                                    changeHandler(e, index);
                                }}
                            />
                            <span className='mr-3'>{app.displayName}</span>

                            {app.active && (
                                <Input
                                    type='select'
                                    value={app.role}
                                    onChange={(e) => {
                                        roleHandler(e, index);
                                    }}
                                >
                                    {availableRoles.map((role) => {
                                        return (
                                            <option key={role} value={role}>
                                                {translateRoleName(role)}
                                            </option>
                                        );
                                    })}
                                </Input>
                            )}
                        </Label>
                    </FormGroup>
                );
            })}
        </div>
    );
};

export default UserApplications;
