import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Alert } from 'reactstrap';
import { Formik } from 'formik';
import LoadingButton from '../../common/LoadingButton';
import { requestPasswordChange } from '../../../api/users';
import Footer from '../../common/Footer';
import { initializeDefaultTheme } from "../../../utils/clientTheme";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    initializeDefaultTheme();
    this.state = {
      PENDING: false,
      SUCCESS: false,
    }
  }

  onSubmitHandler = (values, actions) => {
    this.setState({ PENDING: true });
    requestPasswordChange({ "username": values.email }).then(() => {
      this.setState({ SUCCESS: true, PENDING: false })
    });
  }

  renderForm = () => (
    <Fragment>
      <h2 className="card-title">Reset password</h2>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, actions) => this.onSubmitHandler(values, actions)}
        render={({ handleSubmit, handleChange, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                onChange={handleChange}
                value={values.email}
                type="email"
                name="email"
                id="emailInput"
                placeholder="Email..."
                required
                autoFocus
              />
            </FormGroup>
            <LoadingButton loading={this.state.PENDING} disabled={this.state.PENDING} type="submit" color="c-primary" size="md" block>
              Reset password
            </LoadingButton>
          </Form>
        )}
      />
    </Fragment>
  )

  renderMessage = () => (
    <Fragment>
      <Alert color="success">
        Please check your email inbox for further instructions
      </Alert>
      <LoadingButton onClick={() => this.props.history.push('/login')} type="button" color="c-primary" size="md" block>
        Back to Login
      </LoadingButton>
    </Fragment>
  );

  render() {
    return (
      <Fragment>
        <div className="wrapper bg-ship">
          <Container>
            <Row>
              <Col sm="9" md="7" lg="4" className="mx-auto mt-5">
                <Card className="card-signin my-5">
                  <CardBody>
                    {this.state.SUCCESS ? this.renderMessage() : this.renderForm()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

export default ResetPassword;
