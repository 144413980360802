import axios from 'axios';
import { isAuthenticated } from '../utils/isAuthenticated';
import Cookie from 'js-cookie';

/**
 * Create an Axios Client with defaults
 */

const defaultValues = {};
defaultValues.baseURL = process.env.REACT_APP_API_URL;
defaultValues.headers = {
  'Content-Type': 'application/json',
  'X-Application': 'AdminPanel'
};

const client = axios.create(defaultValues);

/**
 * Request Wrapper with default success/error actions
 */
const request = options => {
  if (isAuthenticated()) {
    if(!!Cookie.get('access_token')){
      client.defaults.headers.common.Authorization = Cookie.get('access_token');
    } else {
      client.defaults.headers.common.Authorization = sessionStorage.getItem('access_token');
    }
  }

  const onSuccess = response => response;
  const onError = error => {
    /* eslint-disable no-console */
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

const createAxiosResponseInterceptor = () => {
  const interceptor = client.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      if (error.response.status !== 401 || (!Cookie.get('refresh_token') && !sessionStorage.getItem('refresh_token'))) {
        return Promise.reject(error);
      }
      /*
        * When response code is 401, try to refresh the token.
        * Eject the interceptor so it doesn't loop in case
        * token refresh causes the 401 response
        */
      axios.interceptors.response.eject(interceptor);
      if(!!Cookie.get('refresh_token')) {
        return request({ method: 'POST', url: '/api/users/authorize', data: { refreshToken: Cookie.get('refresh_token') }})
          .then(response => {
            Cookie.set('access_token', response.data.data.jwt);
            Cookie.set('refresh_token', response.data.data.refreshToken);
            error.response.config.headers['Authorization'] = response.data.data.jwt;
            createAxiosResponseInterceptor();
            return client(error.response.config);
          }).catch(err => {
            Cookie.remove('access_token');
            Cookie.remove('refresh_token');
            createAxiosResponseInterceptor();
            return Promise.reject(err);
        })
      } else {
        return request({ method: 'POST', url: '/api/users/authorize', data: { refreshToken: sessionStorage.getItem('refresh_token') }})
          .then(response => {
            sessionStorage.setItem('access_token', response.data.data.jwt);
            sessionStorage.setItem('refresh_token', response.data.data.refreshToken);
            error.response.config.headers['Authorization'] = response.data.data.jwt;
            createAxiosResponseInterceptor();
            return client(error.response.config);
          }).catch(err => {
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
            createAxiosResponseInterceptor();
            return Promise.reject(err);
        })
      }
    }
  );
}

createAxiosResponseInterceptor();




export default request;
