import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, FormGroup, Input } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../../../common/LoadingButton';
import { assignToApplication, unassignFromApplication, getRoles, getClients } from '../../../../api/users';
import { CurrentUserContext } from '../../../../context/current-user-context';
import UserApplications from './UserApplications';
import AdminUserApplications from './AdminUserApplications';

export default function UserSettingsModal({
    availableClientApps,
    availableClients,
    isOpen,
    toggle,
    className,
    editUserSettings,
    resetPassword,
    PENDING_EDIT_USER_SETTINGS,
    PENDING_RESET_PASSWORD,
    selectedUser,
}) {
    const context = useContext(CurrentUserContext);

    const hasAdminRole = context.currentUser.role === 'admin';
    const [grantedApplications, setGrantedApplications] = useState([]);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [clientApps, setClientApps] = useState([]);
    const [clients, setClients] = useState(availableClients);
    const [selectedClient, setSelectedClient] = useState('');
    const [empty, setEmpty] = useState(false);

    const availableUserApplications = context.currentUser.applications;

    useEffect(() => {
        if (isOpen) {
            setGrantedApplications(selectedUser.userApplications);
        }
    }, [isOpen]);

    useEffect(() => {
        getRoles().then((res) => {
            setAvailableRoles(res.data.data.filter((r) => r !== 'admin'));
        });
    }, []);

    useEffect(() => {
        getClients()
            .then((res) => {
                setClients(res.data.data);
            })
            .catch((err) => {
                if (err.status === 500) {
                    console.error(err);
                }
            });
    }, []);

    const toggleMethod = () => {
        toggle();
    };

    const isCheckboxDisabled = () => {
        return selectedUser && selectedUser.id === context.currentUser.id;
    };

    const checkApplicationsChanges = () => {
        // eslint-disable-next-line
        selectedUser.applications.map((app) => {
            if (!Array.from(grantedApplications).includes(app)) {
                unassignFromApplication(selectedUser.id, { application: app });
            }
        });
        // eslint-disable-next-line
        Array.from(grantedApplications).map((app) => {
            if (!selectedUser.applications.includes(app)) {
                assignToApplication(selectedUser.id, { application: app });
            }
        });
    };

    const onSubmitHandler = async (values) => {
        // await checkApplicationsChanges();
        await editUserSettings(
            empty
                ? {
                      newFirstName: values.firstName,
                      newLastName: values.lastName,
                      newRole: values.role,
                      newCompany: values.company ? values.company : null,
                      isDisabled: values.isDisabled,
                      newUserApplications: grantedApplications,
                      client: selectedClient,
                  }
                : {
                      newFirstName: values.firstName,
                      newLastName: values.lastName,
                      newRole: values.role,
                      newCompany: values.company ? values.company : null,
                      isDisabled: values.isDisabled,
                      newUserApplications: grantedApplications,
                  }
        );
    };

    const handleResetPassword = () => {
        resetPassword({ username: selectedUser.username });
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
        company: Yup.string(),
        client: Yup.string(),
    });

    const updateApplicationsAccess = (newArray) => {
        setGrantedApplications(newArray);
    };

    const updateSelectedClient = (e) => {
        setSelectedClient(e.target.value);
        setGrantedApplications([]);
        const selectedElement = clients.find((el) => el.client === e.target.value);
        setClientApps(selectedElement.applications);
    };

    useEffect(() => {
        if (selectedClient) {
            const selectedElement = clients.find((el) => el.client === selectedClient);
            setClientApps(selectedElement.applications);
        }
    }, [selectedClient]);

    const onRoleChange = ({ e, setFieldValue }) => {
        setFieldValue('role', e.target.checked ? 'admin' : 'user');

        if (e.target.checked) {
            setEmpty(false);
        } else {
            setEmpty(true);
            setGrantedApplications([]);
        }
        setSelectedClient(clients[0].client);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleMethod} className={className}>
            <ModalHeader toggle={toggleMethod}>Edit user</ModalHeader>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: (selectedUser && selectedUser.firstName) || '',
                    lastName: (selectedUser && selectedUser.lastName) || '',
                    company: (selectedUser && selectedUser.company) || '',
                    role: (selectedUser && selectedUser.role) || 'user',
                    isDisabled: (selectedUser && selectedUser.isDisabled) || false,
                }}
                onSubmit={(values, actions) => {
                    onSubmitHandler(values, actions);
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                render={({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <FormGroup className='mb-0'>
                                <Label for='firstName'>
                                    First name <span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    type='text'
                                    id='firstName'
                                    placeholder='First name...'
                                    name='firstName'
                                    onChange={handleChange}
                                    value={values.firstName}
                                    required
                                />
                                {errors.firstName ? (
                                    <div className='small text-danger'>{errors.firstName}</div>
                                ) : (
                                    <div style={{ height: '19px' }} />
                                )}
                            </FormGroup>
                            <FormGroup className='mb-0'>
                                <Label for='lastName'>
                                    Last name <span className='text-danger'>*</span>
                                </Label>
                                <Input
                                    type='text'
                                    id='lastName'
                                    placeholder='Last name...'
                                    name='lastName'
                                    onChange={handleChange}
                                    value={values.lastName}
                                    required
                                />
                                {errors.lastName ? (
                                    <div className='small text-danger'>{errors.lastName}</div>
                                ) : (
                                    <div style={{ height: '19px' }} />
                                )}
                            </FormGroup>
                            <FormGroup className='mb-0'>
                                <Label for='company'>Company</Label>
                                <Input
                                    type='text'
                                    id='company'
                                    placeholder='Company...'
                                    name='company'
                                    onChange={handleChange}
                                    value={values.company}
                                />
                                {errors.company ? (
                                    <div className='small text-danger'>{errors.company}</div>
                                ) : (
                                    <div style={{ height: '19px' }} />
                                )}
                            </FormGroup>

                            {hasAdminRole && (
                                <FormGroup check className='mb-3'>
                                    <Label check for='admin'>
                                        <Input
                                            id='admin'
                                            type='checkbox'
                                            name='role'
                                            checked={values.role === 'admin'}
                                            onChange={(e) => onRoleChange({ e, setFieldValue })}
                                        />
                                        System Admin
                                    </Label>
                                </FormGroup>
                            )}

                            {values.role !== 'admin' && (
                                <>
                                    {hasAdminRole && (
                                        <FormGroup className='mb-0'>
                                            <Label for='client'>Client</Label>
                                            <Input
                                                disabled={selectedUser.role === 'user'}
                                                id='company'
                                                type='select'
                                                placeholder='Select client...'
                                                name='client'
                                                value={
                                                    selectedUser && selectedUser.client === 'SystemAdministration'
                                                        ? selectedClient
                                                        : (selectedUser && selectedUser.client) || ''
                                                }
                                                onChange={updateSelectedClient}
                                            >
                                                {clients.map((client) => (
                                                    <option key={client.client} value={client.client}>
                                                        {client.client}
                                                    </option>
                                                ))}
                                            </Input>
                                            {errors.client ? (
                                                <div className='small text-danger'>{errors.client}</div>
                                            ) : (
                                                <div style={{ height: '19px' }} />
                                            )}
                                        </FormGroup>
                                    )}
                                    <FormGroup className='mb-0'>
                                        <Label for='applicationCheckboxes'>Applications</Label>
                                    </FormGroup>
                                    {empty && selectedUser.role === 'admin' ? (
                                        <AdminUserApplications
                                            update={updateApplicationsAccess}
                                            availableApplications={clientApps}
                                            userApplications={selectedUser.userApplications}
                                            availableRoles={availableRoles}
                                        />
                                    ) : (
                                        <UserApplications
                                            update={updateApplicationsAccess}
                                            availableApplications={
                                                hasAdminRole ? availableClientApps : availableUserApplications
                                            }
                                            userApplications={selectedUser.userApplications}
                                            availableRoles={availableRoles}
                                        />
                                    )}
                                </>
                            )}
                            {hasAdminRole && (
                                <>
                                    <FormGroup className='mt-4 mb-0'>
                                        <Label for='applicationCheckboxes'>Access</Label>
                                    </FormGroup>
                                    <FormGroup className='mb-0' check>
                                        <Label check>
                                            <Input
                                                disabled={isCheckboxDisabled()}
                                                type='checkbox'
                                                name='isDisabled'
                                                onChange={handleChange}
                                                checked={values.isDisabled}
                                            />{' '}
                                            Disabled
                                        </Label>
                                    </FormGroup>
                                </>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <LoadingButton
                                loading={PENDING_RESET_PASSWORD}
                                disabled={PENDING_RESET_PASSWORD}
                                color='danger'
                                className='modal-button-reset'
                                onClick={handleResetPassword}
                            >
                                Reset password
                            </LoadingButton>{' '}
                            <LoadingButton
                                loading={PENDING_EDIT_USER_SETTINGS}
                                disabled={PENDING_EDIT_USER_SETTINGS || (empty && grantedApplications.length === 0)}
                                color="c-primary"
                                type='submit'
                            >
                                Submit changes
                            </LoadingButton>{' '}
                            <Button color='c-secondary' onClick={toggleMethod}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            />
        </Modal>
    );
}
