import React from 'react';
import ReactDOM from 'react-dom';
import 'promise-polyfill/src/polyfill';
import 'es6-object-assign/auto';
import '@babel/polyfill';
import App from './components/App';
import './assets/styles/index.scss';
import {IntlProvider} from 'react-intl';

ReactDOM.render(
    <IntlProvider locale="en">
        <App />
    </IntlProvider>,
    document.getElementById('root'));