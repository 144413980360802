import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Pagination = ({ pagination, history, match: { params: { num }}}) => {
  if (!pagination || (pagination.total_items_count <= pagination.limit)) return null;
  const currentPage = parseInt(num) || 1;
  const numberOfPages = Math.ceil(pagination.total_items_count / pagination.limit);

  const handlePageClick = ({ selected }) => {
    history.push(`/users/page/${selected + 1}`)
  };

  return (
      <div className="pt-3">
        <ReactPaginate
            nextLabel={`>`}
            previousLabel={`<`}
            breakLabel={<PaginationLink> ... </PaginationLink>}
            breakClassName="break-me"
            forcePage={currentPage - 1}
            pageCount={numberOfPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            pageClassName="page-item"
            previousClassName="page-item"
            previousLinkClassName="page-c-link"
            nextClassName="page-item"
            nextLinkClassName="page-c-link"
            pageLinkClassName="page-c-link"
            activeClassName="active"
            disabledClassName="disabled"
            hrefBuilder={() => '#'}
        />
      </div>
  );
};

export default withRouter(Pagination);
