import React, { useState, useEffect, useRef, useContext } from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap';
import LoadingButton from '../../common/LoadingButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {createUser, getClients, getRoles} from '../../../api/users';
import NotificationAlert from 'react-notification-alert';
import AppCheckbox from './AppCheckbox';
import { CurrentUserContext } from '../../../context/current-user-context';
import {renderApplicationsWithDisplayName, sortApplicationsByDisplayName} from "../../../utils/helpers";

const AddUserForm = ({ history, user, freshEmail }) => {
  const context = useContext(CurrentUserContext);

  const [SUBMIT_PENDING, setSUBMIT_PENDING] = useState(false);
  const [userApplications, setUserApplications] = useState([]);
  const [isAddButtonActive, setIsAddButtonActive] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientApps, setClientApps] = useState([]);
  const [selectedClient, setSelectedClient] = useState( user ? user.client : '');

  const [initialValues] = useState(user || { username: freshEmail || '', firstName: '', lastName: '', company: '', client: '' });

  let hasAdminRole = false;
  if (user && user.role === "admin") hasAdminRole = true;
  const [isAdmin, setAdmin] = useState(hasAdminRole);

  const [ROLES_LOADING, setROLES_LOADING] = useState(false);
  const refNotification = useRef();

  const updateSelectedClient = (e) => {
    setSelectedClient(e.target.value);

      const selectedElement = clients.find(el => el.client === e.target.value);
      setClientApps(selectedElement.applications);
      setUserApplications([]);
  };

  const triggerNotification = (message) => {
    refNotification.current.notificationAlert({
      place: 'tr',
      message,
      type:'danger',
      autoDismiss: 5
    });
  };

  const matchedUserApplications = context.currentUser.applications && context.currentUser.applications.filter(el => clientApps.includes(el)) || [];

  useEffect(() => {
    ((!user && userApplications.length)  || isAdmin|| (user && userApplications.length && userApplications.length !== matchedUserApplications.length)) ? (
        setIsAddButtonActive(true)
    ) : (
        setIsAddButtonActive(false)
    )
  }, [userApplications, isAdmin]);

  useEffect(() => {
    getClients().then(res => {
      setClients(res.data.data);
      setSelectedClient(res.data.data[0].client);
      setClientApps(res.data.data[0].applications);

      if (user && user.client) {
        setClientApps(res.data.data.find(el => el.client === user.client).applications);

      }
    }).catch(err => {
      if (err.status === 500) {
        triggerNotification('Internal server error');
      }
    });
  }, []);

  useEffect(() => {
    setROLES_LOADING(true);
    getRoles()
    .then(res => {
      setAvailableRoles(res.data.data.filter(r => r !== 'admin'));
      setROLES_LOADING(false);
    })
    .catch(err => {
      if (err.status === 500) {
        triggerNotification('Internal server error');
      }
    })
  }, []);

  const onSubmitHandler = (values, actions) => {
    if(SUBMIT_PENDING) return;
    setSUBMIT_PENDING(true);

    const model = {
      username: values.username,
      firstName: values.firstName,
      lastName: values.lastName,
      role: isAdmin ? "admin" : undefined,
      company: values.company ? values.company : null,
      userApplications: isAdmin ? [] : userApplications,
      client: selectedClient
    };

    if (!isAdmin && userApplications.length < 1) {
      triggerNotification('Choose at least one application');
      setSUBMIT_PENDING(false);
      return;
    }

    createUser(model)
      .then(() => {
        history.push("/users")
      })
      .catch((err) => {
        if(err.status === 409) {
          actions.setFieldError('username', 'This username is already taken');
        } else if (err.status === 400) {
          triggerNotification('Bad request');
        } else {
          triggerNotification('Internal server error');
        }
        setSUBMIT_PENDING(false);
      });
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    company: Yup.string().nullable()
  });

  const handleCheckboxChange = (active, app) => {
    const index = userApplications.findIndex(userApp => userApp.application === app.application);
    if (index < 0 && active) {
      setUserApplications(prevState => {
        return [...prevState, app];
      });
    }

    if (index > -1) {
      const updatedArray = [...userApplications];
      updatedArray.splice(index, 1);

      if (active) {
        updatedArray.push(app);
      }
      setUserApplications(updatedArray);
    }
  };

  const renderApplications = (availableApplications) => {
    return availableApplications.map(app => {
      let initialValue = null;

      if (user && user.userApplications && user.userApplications.length) {
        user.userApplications.forEach(uA => {
          if (uA.application === app.originalName) {
            initialValue = uA;
          }
        })
      }

      return <AppCheckbox initialValue={initialValue} key={app.originalName} app={app.originalName} roles={availableRoles} handler={handleCheckboxChange} />;
    })
  };

  const updateAdmin = (e) => {
    setAdmin(e.target.checked);
  };

  const applicationsWithDisplayName = renderApplicationsWithDisplayName(matchedUserApplications);
  const sortedApplications = sortApplicationsByDisplayName(applicationsWithDisplayName);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => onSubmitHandler(values, actions)}
      validationSchema={validationSchema}
      validateOnChange={false}
      render={({ handleSubmit, handleChange, values, errors }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-0">
            <Label for="username">E-mail <span className="text-danger">*</span></Label>
            <Input disabled={user || freshEmail} type="email" id="username" placeholder="E-mail..." name="username" onChange={handleChange} value={values.username} required/>
            {errors.username ? <div className="small text-danger">{errors.username}</div> : <div style={{height: '19px'}} />}
          </FormGroup>
          <FormGroup className="mb-0">
            <Label for="firstName">First name <span className="text-danger">*</span></Label>
            <Input disabled={user} type="text" id="firstName" placeholder="First name..." name="firstName" onChange={handleChange} value={values.firstName} required/>
            {errors.firstName ? <div className="small text-danger">{errors.firstName}</div> : <div style={{height: '19px'}} />}
          </FormGroup>
          <FormGroup className="mb-0">
            <Label for="lastName">Last name <span className="text-danger">*</span></Label>
            <Input disabled={user} type="text" id="lastName" placeholder="Last name..." name="lastName" onChange={handleChange} value={values.lastName} required/>
            {errors.lastName ? <div className="small text-danger">{errors.lastName}</div> : <div style={{height: '19px'}} />}
          </FormGroup>
          <FormGroup className="mb-0">
            <Label for="company">Company</Label>
            <Input disabled={user} type="text" id="company" placeholder="Company..." name="company" onChange={handleChange} value={values.company}/>
            {errors.company ? <div className="small text-danger">{errors.company}</div> : <div style={{height: '19px'}} />}
          </FormGroup>
          {context.currentUser.role === "admin" && <FormGroup check className="mb-3">
            <Label check for="admin">
              <Input disabled={!!user} defaultChecked={isAdmin} id="admin" type="checkbox" value={isAdmin} onChange={updateAdmin} />
              System Admin
            </Label>
          </FormGroup>}
          {isAdmin === false && (
            <>
            {context.currentUser.role === "admin" && (
              <FormGroup className="mb-0">
                <Label for="client">Client</Label>
                <Input disabled={user} id="company" type="select" placeholder="Select client..." name="client" value={selectedClient} onChange={updateSelectedClient}>
                  {clients.map(client => <option key={client.client} value={client.client}>{client.client}</option>)}
                </Input>
                {errors.client ? <div className="small text-danger">{errors.client}</div> : <div style={{height: '19px'}} />}
              </FormGroup>
            )}

              <FormGroup className="mb-0">
                <Label for="applicationCheckboxes">Applications</Label>
                {renderApplications(sortedApplications)}
              </FormGroup>
            </>
          )}
          <LoadingButton loading={SUBMIT_PENDING} disabled={SUBMIT_PENDING || !isAddButtonActive} color="c-primary" className="mt-3" type="submit">Add User</LoadingButton>
          <NotificationAlert ref={refNotification} />
        </Form>
      )}
    />
  )
};

export default AddUserForm;
